<template>
  <div class="events-section">
    <div class="event-calendar">
      <vc-calendar
      locale="ru"
        :attributes="events"
        is-expanded
        :min-date='new Date()'
        @dayclick="dayClick"
        @update:from-page="periodClick"
      />
      <div class="events-desc">
        <div class="desc-block type1">Особые мероприятия</div>
        <div class="desc-block type2">Конференции, мастер-классы</div>
        <div class="desc-block type3">Вебинары, онлайн-обучение</div>
      </div>
    </div>
    <div class="event-calendar-info">
      <div class="reg-window" :class="{ active: (showRegistrationForm && (!registrationComplete) && (!existUser))}">
        <span class="close-ico js-close-ico" @click="closeForm"></span>
        <div class="reg-form">
          <div class="reg-title">
            Регистрация
          </div>
          <div class="reg-text">
            За час до мероприятия мы отправим <br />
            вам СМС с трансляцией
          </div>
          <div class="reg-fields">
            <div class="reg-field">
              <label>ФИО</label>
              <input type="text" required v-model="user.fio"  />
              <span class="formError">
                {{formError}}
              </span>
            </div>
            <div class="reg-field">
              <label>Номер</label>
              <masked-input
                mask="\+\7 (111) 111-1111"
                required
                type="tel"
                v-model="user.phone"
              />
              <span class="formError">
                {{formError}}
              </span>
            </div>
            <div class="reg-field">
              <label>Специальность</label>
              <select v-model="user.speciality" required>
                <option>Невропатолог</option>
                <option>Невролог</option>
                <option>Кардиолог</option>
                <option>Терапевт</option>
                <option>ВОП</option>
                <option>Педиатр</option>
                <option>Гинеколог</option>
                <option>Аллерголог</option>
                <option>Травматолог</option>
                <option>ЛОР</option>
              </select>
              <span class="formError">
                {{formError}}
              </span>
            </div>
          </div>
          <button class="reg-btn" @click="submitRegistrationForm">
            Зарегистрироваться
          </button>
        </div>
      </div>
      <div v-if="(eventInDay.length) && (!registrationComplete) && (!existUser)">
        <div class="title">
          {{ currentPageEventData.subject }}
        </div>
        <div class="event-desc">
          <div class="event-row clearfix">
            <div class="event-name">Тип</div>
            <div class="event-desc">{{ currentPageEventData.type }}</div>
          </div>
          <div class="event-row clearfix">
            <div class="event-name">Дата</div>
            <div class="event-desc">
              {{ getStringDate(currentPageEventData.start_date) }} -
              {{ getStringDate(currentPageEventData.end_date) }}
            </div>
          </div>
          <div class="event-row clearfix">
            <div class="event-name">Время</div>
            <div class="event-desc">
              {{ currentPageEventData.start_time }}-{{
                currentPageEventData.end_time
              }}
            </div>
          </div>
          <div class="event-row clearfix">
            <div class="event-name">Формат</div>
            <div class="event-desc">{{ currentPageEventData.format }}</div>
          </div>
          <div class="event-row speakers clearfix">
            <div class="event-name">Спикеры</div>
            <div class="event-desc">
              <div
                class="speaker"
                v-for="speaker in currentPageEventData.speakers"
                v-bind:key="speaker.id"
              >
                <div class="photo">
                  <img :src="speaker.photo" />
                </div>
                <div class="desc">
                  <div class="name">{{ speaker.full_name }}</div>
                  <div class="text">{{ speaker.speciality }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="btns">
          <button class="reg-btn js-reg-btn" @click="showForm" v-if="currentPageEventData.is_default && !showRegistrationForm">
            Регистрация
          </button>
          <a :href="currentPageEventData.external_url" v-if="!currentPageEventData.is_default && currentPageEventData.external_url && !showRegistrationForm">
            <button class="reg-btn js-reg-btn">
              Регистрация
            </button>
          </a>
          <div class="arrows">
            <button class="arrow prev" :class="{disabled: currentPage===0}" @click="pageMinus">
              <img src="img/cal-arrow.svg" alt="arrow" />
            </button>
            <button class="arrow next" @click="pagePlus" :class="{disabled: !(currentPage < (eventInDay.length-1))}" >
              <img src="img/cal-arrow.svg" alt="arrow" />
            </button>
          </div>
          </div>

        </div>
      </div>
      <div class="event-default" v-if="(!currentPageEventData) && (!registrationComplete) && (!existUser)">
        <div class="title">
          На эту дату мероприятий нет
        </div>
      </div>
      <div class="event-registrationComplete" v-if="registrationComplete">
        <h4 class="reg-title">
          Регистрация прошла успешно!
        </h4>
        <p class="reg-text">
          За час до мероприятия мы отправим вам СМС с трансляцией.
        </p>
        <button class="reg-btn" @click="registrationComplete=false">
          Буду ждать
        </button>
      </div>
      <div class="event-registrationComplete" v-if="existUser">
        <h4 class="reg-title">
          Такой номер телефона уже был зарегистрирован ранее
        </h4>
        <p class="reg-text">
          Если вы уверены, что не регистрировались, введите другой номер
          телефона
        </p>
        <button class="reg-btn" @click="existUser=false">
          Понятно
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'TevaCalendar',
  data () {
    return {
      loading: false,
      showRegistrationForm: false,
      formError: '',
      user: {
        fio: '',
        phone: '',
        speciality: ''
      },
      currentPage: 0,
      registrationComplete: false,
      existUser: false
    }
  },
  computed: {
    events () {
      return this.$store.getters.getEvents
    },
    eventInDay () {
      return this.$store.getters.getEventInDay
    },
    currentPageEventData () {
      return this.eventInDay[this.currentPage]
    }
  },
  watch: {
    eventInDay (newValue) {
      this.currentPage = 0
    }
  },
  async mounted () {
    await this.$store
      .dispatch('fetchSpecialEvent')
  },
  methods: {
    pagePlus () {
      if (this.currentPage === this.eventInDay.length - 1) {
        return
      }
      this.currentPage++
    },
    pageMinus () {
      if (this.currentPage === 0) {
        return
      }
      this.currentPage--
    },
    getStringDate (date) {
      const momentDate = moment(date)
      return momentDate.locale('ru').format('DD MMMM YYYY')
    },
    async dayClick (value) {
      this.registrationComplete = false
      this.existUser = false
      if (value.id) {
        this.loading = true
        await this.$store
          .dispatch('fetchEventByDate', value.id)
          .then(({ data }) => {
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    async periodClick (data) {
      const firstDayOfMonth =
        data.year +
        '-' +
        (data.month < 10 ? '0' + data.month : data.month) +
        '-01'
      this.loading = true
      await this.$store
        .dispatch('fetchEvents', firstDayOfMonth)
        .then(({ data }) => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    showForm () {
      this.showRegistrationForm = true
    },
    closeForm () {
      this.showRegistrationForm = false
    },
    async submitRegistrationForm () {
      if (this.user.fio && this.user.phone && this.user.speciality) {
        const response = await this.$store.dispatch('registerUser', this.user)
        if (!response.data.success) {
          this.formError = ''
          this.existUser = true
          this.closeForm()
        } else {
          this.existUser = false
          this.registrationComplete = true
          this.closeForm()
          this.formError = ''
        }
        this.loading = true
      } else {
        this.formError = 'Заполните поле'
      }
      this.loading = false
    }
  }
}
</script>

<style>
.btns {
    display: flex;
    justify-content: space-between;
}
.btns .arrows {
  position: static !important;
}
.event-row.speakers .event-desc {
    overflow: auto;
    padding-bottom: 10px
}

.event-row.speakers .event-desc::-webkit-scrollbar {
  height: 5px ;
  box-shadow: 0 0 5px #0101011e;
  border-radius: 20px;
}

.event-row.speakers .event-desc::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
   border-radius: 20px;
}

.event-row.speakers .event-desc .speaker {
  margin-right: 20px;
}

.event-default {
    background: inherit;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: inherit;
    z-index: 10;
}
.vc-container {
    border: none;
     font-family: avenir;

}
.vc-header {
    margin-bottom: 1.25rem;
}
.vc-weekday {
    font-weight: normal !important;
    font-size: 1.042rem !important;
    color: #B0B0B0 !important;
    font-family: avenir-medium;
}
.vc-title {
    text-transform: capitalize;
}
span.vc-day-content {
    font-size: 1.042rem !important;
}
.vc-day {
 margin-top: 0.521rem !important;
 font-family: 'notosans';
}
.vc-arrow {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 16px;
}
.vc-arrow.is-right {
    right: 30%;
    background: url(/img/cal-right.svg) no-repeat center;
}
.vc-arrow.is-left {
    left: 30%;
    background: url(/img/cal-left.svg) no-repeat center;
}

@media (min-width: 320px) and (max-width: 767px)  {
  .vc-arrow.is-left {
    left: 24%;
  }
  .vc-arrow.is-right {
    right: 24%;
  }
}

span.vc-dot {
    position: absolute;
    right: 1rem;
    top: 20%;
    width: 0.938rem !important;
    height: 0.938rem !important;
    margin: 0 !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .vc-dots {
     position: static;
    top: 11px;
    right: -12px;
  }
  span.vc-dot {
    position: static;
  }
}

span.vc-dot:nth-child(2) {
  right: 0.5rem;
  z-index: 1;
}

span.vc-dot:nth-child(3) {
    z-index: 2;
    right: 0rem;
}

span.vc-dot:nth-child(4) {
    z-index: 3;
    right: -0.5rem;
}

span.vc-dot:nth-child(5) {
    z-index: 4;
    right: -1rem;
}

@media (max-width: 1500px) {
  span.vc-dot {
    top: 30%;
  }
}

@media (min-width: 320px) and (max-width: 767px)  {
  span.vc-dot {
    /* right: -2px;
    top: 8px; */
    width: 0.538rem !important;
    height: 0.538rem !important;
  }
}

.vc-day-content[data-v-005dafc8]:focus {
  background: none;
  color: #AA198D;
}

.formError {
  color: #ffc107;
}

.weekday-position-6 {
    color: #FB3449;
}
.weekday-position-7 {
    color: #FB3449;
}
.vc-arrow svg {
    display: none  !important;
}

.event-desc .arrows {
  position: absolute;
  right: 40px;
  display: flex;
  bottom: 30px;
  align-items: center;
}

.event-desc .arrows .arrow {
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff3;
}

.event-desc .arrows .arrow:hover {
  background: linear-gradient(90deg, #afcb37 0%, #00a03b 100%);
}
.event-desc .arrows .arrow.disabled {
  opacity: 0.2;
  pointer-events: none;
}
.event-desc .arrow.prev {
  margin-right: 10px;
  transform: rotate(180deg);
}
.event-registrationComplete {
  position: absolute;
  top: 0;
  left: 0;
  padding: inherit;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: inherit;
}
.event-registrationComplete .reg-title {
  padding-right: 33%;
}
.event-registrationComplete .reg-text {
  font-family: avenir;
  font-size: 1.25rem;
  padding-right: 28%;
}
.event-registrationComplete .reg-btn {
  width: 202px;
  bottom: 40px;
  left: 20px !important;
  margin: 0 !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .event-registrationComplete .reg-text,
  .event-registrationComplete .reg-title {
    padding-right: 0;
  }
}

.event-thanks {
    position: absolute;
    top: 0;
    left: 0;
    padding: inherit;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: inherit;
}
.event-thanks .reg-title {
  padding-right: 33%;
}
.event-thanks .reg-text {
    font-family: avenir;
    font-size: 1.25rem;
    padding-right: 28%;
}

.event-thanks .reg-btn {
    width: 202px;
    bottom: 40px;
    left: 20px !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .event-thanks .reg-text, .event-thanks .reg-title  {
    padding-right: 0;
  }
}

.events-section .event-calendar-info .event-desc .event-row .event-desc .speaker .photo img {
  object-fit: cover;
}
</style>
