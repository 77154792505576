<template>
  <div class="event-slider-section">
    <div class="swiper-container event-slider">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="training in trainings" v-bind:key="training.id">
          <router-link :to="'/training/' + training.id"  class="event-info-block" replace>
            <div class="pic" :style="{ backgroundImage: `url('${training.preview_image}')` }"></div>
            <div class="desc type1">
              <div class="theme">{{ training.type }}</div>
              <div class="text">{{ training.title }}</div>
              <div class="text-inform">{{training.description_preview}}</div>
              <div class="date">{{ getStringDate(training.date) }}</div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-button-next next"></div>
      <div class="swiper-button-prev prev"></div>
      <router-link to="/training" class="arrow-link">Все лекции</router-link>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Autoplay } from 'swiper'
import moment from 'moment'
Swiper.use([Navigation, Autoplay])

export default {
  name: 'TevaSlider',
  async mounted () {
    await this.$store
      .dispatch('fetchMainPageTraining')
      .then(({ data }) => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
    await this.bannerSwiper()
  },
  computed: {
    trainings () {
      return this.$store.getters.getMainPageTrainings
    }
  },
  methods: {
    getStringDate (date) {
      const momentDate = moment(date)
      return momentDate.locale('ru').format('DD MMMM YYYY')
    },
    bannerSwiper () {
      // eslint-disable-next-line no-new
      new Swiper('.event-slider', {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        autoplay: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 4
          }
        }
      })
    }
  }
}
</script>

<style>
.text-inform {
    font-size: 12px;
    line-height: normal;
    margin-top: 10px;
    position: relative;
}
</style>
