<template>
  <div class="main-page content">
    <div class="main-screen-section">
      <div class="container">
        <div
          class="about-desc"
          data-aos="fade-right"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
        >
          <h1 style="color: #fff;" class="title">
            Головные боли <br />
            и мигрень
          </h1>
          <div class="text">
            Головные боли одни из распространенных расстройств
            <br class="visible-xs" />
            нервной системы <br class="hidden-xs" />
            и существенно нарушают качество жизни <br />
            и трудоспособность
          </div>
          <router-link to="/headaches" class="button button-arrow"
            ><span>Подробнее</span></router-link
          >
        </div>
      </div>
    </div>

    <div class="container">
      <div class="illnes-section">
        <div class="row">
          <div
            class="col-md-6"
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            <router-link
              to="/migraine-pathogenesis"
              class="ill-block big type1"
            >
              <div
                class="pic"
                style="
                  background: url(img/pic1.jpg) center center no-repeat;
                  background-size: cover;
                "
              ></div>
              <div class="desc">
                <div class="title">Патогенез мигрени</div>
                <div class="text">
                  Выявлено большое число факторов, которые могут спровоцировать
                  приступ мигрени
                </div>
                <span class="button">Подробнее</span>
              </div>
            </router-link>
          </div>
          <div
            class="col-md-6"
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <div class="row">
              <div class="col-md-6">
                <router-link
                  to="/clinical-manifestations"
                  class="ill-block small type2"
                >
                  <div
                    class="pic"
                    style="
                      background: url(img/pic2.jpg) center center no-repeat;
                      background-size: cover;
                    "
                  ></div>
                  <div class="desc">
                    <div class="title">Клинические проявления мигрени</div>
                    <span class="button">Подробнее</span>
                  </div>
                </router-link>
              </div>
              <div class="col-md-6">
                <router-link to="/diagnostics" class="ill-block small type3">
                  <div
                    class="pic"
                    style="
                      background: url(img/pic3.jpg) center center no-repeat;
                      background-size: cover;
                    "
                  ></div>
                  <div class="desc">
                    <div class="title">Диагностика мигрени</div>
                    <span class="button">Подробнее</span>
                  </div>
                </router-link>
              </div>
              <div class="col-md-6">
                <router-link
                  to="/living-with-migraines"
                  class="ill-block small type4"
                >
                  <div
                    class="pic"
                    style="
                      background: url(img/pic4.jpg) center center no-repeat;
                      background-size: cover;
                    "
                  ></div>
                  <div class="desc">
                    <div class="title">Жизнь с мигренью</div>
                    <span class="button">Подробнее</span>
                  </div>
                </router-link>
              </div>
              <div class="col-md-6">
                <router-link to="/therapy" class="ill-block small type5">
                  <div
                    class="pic"
                    style="
                      background: url(img/pic5.jpg) center center no-repeat;
                      background-size: cover;
                    "
                  ></div>
                  <div class="desc">
                    <div class="title">Лечение и профилактика мигрени</div>
                    <span class="button">Подробнее</span>
                  </div>
                </router-link>
              </div>
            </div>
            <span id="calendar-anchor"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="sub-section">
      <div class="container">
        <h2
          class="b-title"
          data-aos="fade-right"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          Календарь будущих событий
        </h2>
        <TevaCalendar />

        <h2
          class="b-title"
          data-aos="fade-right"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          Прошедшие <br class="visible-xs" />
          вебинары, <br />видеолекции, <br class="visible-xs" />мастер-классы
        </h2>

        <TevaSlider />

        <a
          href="https://neuro-lessons.kz/"
          target="_blank"
          class="sertificate-lesson"
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <div class="desc">
            <div class="title">
              Сертифицированный цикл дистанционного <br />
              повышения квалификации
            </div>
            <div class="link">neuro-lessons.kz</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import TevaCalendar from '@/components/TevaCalendar'
import TevaSlider from '@/components/TevaSlider'
// import Cookies from 'js-cookie'
export default {
  name: 'Home',
  metaInfo: {
    title: 'Головные боли и мигрень',
    meta: [
      { name: 'description', content: 'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность' }
    ]
  },
  components: {
    TevaCalendar,
    TevaSlider
  },
  data: function () {
    return {
      loading: false
    }
  }
}
</script>
